import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { PostHogProvider } from 'posthog-js/react'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

const root = document.getElementById('root')

ReactDOM.render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={{
                api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
                session_recording: {
                    maskAllInputs: false,
                    maskInputOptions: {
                        password: true,
                    },
                },
            }}
        >
            <Suspense fallback={<></>}>
                <App />
            </Suspense>
        </PostHogProvider>
    </React.StrictMode>,
    root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
