import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { User } from 'types/user.types'

const sdk = getCTSDK()

export const useLoginOld = () => {
    return useMutation((params: { email: string; password: string }) =>
        sdk.post<{ token: string; refreshToken: string }>('/loginOld', params)
    )
}

export const useLogin = () => {
    return useMutation((params: { email: string }) =>
        sdk.post<{ token: string; refreshToken: string }>('/login', params)
    )
}

export const useMagicLinkLogin = () => {
    return useMutation((params: { email: string; loginToken: string }) =>
        sdk.post<{ token: string; refreshToken: string }>(
            '/magicLinkLogin',
            params
        )
    )
}

export const useSwitchOrg = () => {
    return useMutation((orgId: string) =>
        sdk.post<{ token: string; refreshToken: string }>('/users/orgSwitch', {
            orgId,
        })
    )
}

export const useRegister = () => {
    return useMutation(
        ({
            email,
            orgId,
            password,
            firstName,
            lastName,
        }: {
            email: string
            orgId: string
            password: string
            firstName: string
            lastName: string
        }) =>
            sdk.post<{ token: string }>('/register', {
                email,
                orgId,
                password,
                firstName,
                lastName,
            })
    )
}

type Model = User
const BASE_URL = '/users'
const ELEMENT_NAME = 'user'

export const useCreateUser = () => {
    return useMutation((data: Partial<Model>) =>
        sdk
            .post<{ [ELEMENT_NAME]: Model }>(BASE_URL, {
                data,
            })
            .then((res) => res.data[ELEMENT_NAME])
    )
}

export const useUpdateUser = () => {
    return useMutation((data: { id: string; [ELEMENT_NAME]: Partial<Model> }) =>
        sdk
            .put<{ [ELEMENT_NAME]: Model }>(`${BASE_URL}/${data.id}`, {
                [ELEMENT_NAME]: data[ELEMENT_NAME],
            })
            .then((res) => res.data[ELEMENT_NAME])
    )
}

export const useDeleteUsers = () => {
    return useMutation((ids: string[]) =>
        sdk.delete(BASE_URL, {
            data: { ids },
        })
    )
}
