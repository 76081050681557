import styled from '@emotion/styled'
import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import { AnchorTag } from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/urls'
import { SPACING } from 'constants/spacing.constants'
import { getImageUrl } from 'utils/getImageUrl'
import { enterKeyPress } from 'utils/keyboard.utils'

const emptyFunc = () => undefined

const StyledLogin = styled.div`
    height: 100%;
    display: flex;

    .login__left {
        width: 50%;
        background-color: ${BASE_TOKENS.grey[900]};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
        padding-left: 3.2rem;
        padding-right: 3.2rem;

        .login__left__logo {
            display: flex;
            align-items: center;
            column-gap: ${SPACING.p1};

            .login__left__logo__image {
                height: 2.4rem;
            }

            .login__left__logo__text {
                color: ${BASE_TOKENS.grey[0]};
                font-family: Inter;
                font-size: 3rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .login__right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 3.6rem;
        padding-bottom: 2.4rem;

        .login__right__register {
            display: flex;
            justify-content: flex-end;
            padding-left: 3.6rem;
            padding-right: 3.6rem;
        }

        .login__right__form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 2.4rem;
            padding-left: 8.8rem;
            padding-right: 8.8rem;

            .login__right__form__text {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 1.2rem;
            }

            .login__right__form__inputs {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 1.6rem;
            }
        }
    }
`

type LoginProps = {
    isSubmitting: boolean
    email: string
    password: string
    onLogin: () => void
    onChangeEmail: (value: string) => void
    onGoToRegister: () => void
    onChangePassword: (value: string) => void
}

const Login = ({
    isSubmitting,
    email,
    password,
    onLogin = emptyFunc,
    onChangeEmail = emptyFunc,
    onGoToRegister = emptyFunc,
    onChangePassword = emptyFunc,
}: LoginProps) => {
    return (
        <StyledLogin>
            <div className="login__left">
                <div className="login__left__logo">
                    <img
                        alt="Logo"
                        src={getImageUrl('shortLogoWhite.png')}
                        className="login__left__logo__image"
                    />
                    <p className="login__left__logo__text">Cartage</p>
                </div>
            </div>
            <div className="login__right">
                <div className="login__right__register">
                    <Button variant="secondary" onClick={onGoToRegister}>
                        Create an account
                    </Button>
                </div>
                <div className="login__right__form">
                    <div className="login__right__form__text">
                        <Typography color="primary" variant="h2">
                            Login
                        </Typography>
                        <Typography color="secondary" variant="body1">
                            Enter your credentials below to login
                        </Typography>
                    </div>
                    <div className="login__right__form__inputs">
                        <TextInput
                            value={email}
                            placeholder="name@example.com"
                            onChange={onChangeEmail}
                        />
                        <TextInput
                            isPassword
                            value={password}
                            placeholder="Password"
                            onChange={onChangePassword}
                            onKeyDown={enterKeyPress(onLogin)}
                        />
                        <Button
                            isDisabled={!email || !password}
                            isSubmitting={isSubmitting}
                            isFullWidth
                            variant="primary"
                            onClick={onLogin}
                        >
                            Continue
                        </Button>
                    </div>
                    <div>
                        <Typography color="secondary" variant="body2">
                            By clicking continue, you agree to our{' '}
                            <AnchorTag
                                rel="noreferrer noopener"
                                href={TERMS_OF_USE_URL}
                                color="secondary"
                                target="_blank"
                            >
                                Terms of Use
                            </AnchorTag>{' '}
                            and{' '}
                            <AnchorTag
                                rel="noreferrer noopener"
                                href={PRIVACY_POLICY_URL}
                                color="secondary"
                                target="_blank"
                            >
                                Privacy Policy
                            </AnchorTag>
                            .
                        </Typography>
                    </div>
                </div>
            </div>
        </StyledLogin>
    )
}

export default Login
