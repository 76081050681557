import { pxToRem } from 'utils/rem.utils'

export const SPACING = {
    /**
     * 0px
     */
    p0: pxToRem(0),
    /**
     * 4px
     */
    sm: pxToRem(4),
    /**
     * 8px
     */
    p1: pxToRem(8),
    /**
     * 16px
     */
    p2: pxToRem(16),
    /**
     * 24px
     */
    p3: pxToRem(24),
    /**
     * 32px
     */
    p4: pxToRem(32),
    /**
     * 40px
     */
    p5: pxToRem(40),
} as const
