import { User } from 'types/user.types'

export const getInitials = ({
    firstName,
    lastName,
}: {
    firstName: string
    lastName: string
}): string => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName ? lastName[0].toUpperCase() : ''

    return `${firstInitial}${lastInitial}`
}

export const isSuperAdmin = (user: User): boolean => {
    return user && user.role === 'superadmin'
}

export const isOpsUser = (user: User): boolean => {
    return user && user.role === 'user'
}

export const isCustomerUser = (user: User): boolean => {
    return user && user.role === 'customerUser'
}

export const getFullName = (
    user: Pick<User, 'firstName' | 'lastName'>
): string => {
    const firstName = user.firstName || ''
    const lastName = user.lastName || ''
    return [firstName, lastName].filter(Boolean).join(' ')
}
