import { Route } from 'react-router-dom'
import AdminRoute from '../../routes/AdminRoute'
import { lazy } from 'react'

const Admin = lazy(
    () =>
        import(/* webpackChunkName: 'Admin' */ 'superAdminPortal/modules/Admin')
)

export const SuperAdminRoutes = () => {
    return (
        <Route path="admin" element={<AdminRoute />}>
            <Route index element={<Admin />} />
        </Route>
    )
}
