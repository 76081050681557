import { Route } from 'react-router-dom'
import Login from 'publicPages/modules/Login'
import {
    LoginForm,
    LoginLayout,
    LoginSuccess,
} from 'publicPages/modules/LoginForm'
import { lazy } from 'react'

const Register = lazy(
    () =>
        import(
            /* webpackChunkName: 'Register' */ 'publicPages/modules/Register'
        )
)

const Tracking = lazy(
    () =>
        import(
            /* webpackChunkName: 'Tracking' */ 'publicPages/modules/Tracking'
        )
)

export const PublicRoutes = () => {
    return (
        <>
            <Route path="/login" element={<LoginLayout />}>
                <Route index element={<LoginForm />} />
                <Route path="success" element={<LoginSuccess />} />
            </Route>
            <Route path="/user/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tracking" element={<Tracking />} />
        </>
    )
}
